$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

$product-header-spacer: 40px;

.product-header {
  border-bottom: 0;
  margin: $spacer-xxs $spacer-s 17px $spacer-s;
  padding-bottom: 0;

  @include breakpoint(tablet) {
    margin: 0 $product-header-spacer $spacer-s $product-header-spacer;
  }

  @include breakpoint(desktop) {
    margin: 0 0 $spacer-s 0;
  }

  &--overlay {
    border-bottom: unset;
    margin: 0;
    padding: 0 !important;

    /* stylelint-disable selector-pseudo-class-no-unknown */
    :global {
      #bv-hosted-review-header {
        height: 100%;
        min-height: auto;

        .bv_main_container {
          align-items: stretch !important;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }

        .bv_sub_container {
          align-items: center !important;
          display: flex !important;
          min-height: 22px !important;
        }

        .bv_text {
          padding-top: 0 !important;
        }

        .bv_averageRating_component_container,
        .bv_numReviews_component_container {
          line-height: 2 !important;
        }
      }
    }
  }
}

.product-header__title {
  @include font($heading-medium);

  display: flex;
  justify-content: space-between;
  transition: all 150ms ease-out;

  &--overlay {
    display: block;

    @include breakpoint(desktop) {
      display: flex;
    }

    &-scrolling {
      @include font($heading-small);
    }
  }
}

.product-header__title-name {
  padding-right: 18px;
  transition: all 150ms ease-out;

  @include breakpoint(tablet) {
    @include font($heading-medium);
  }

  @include breakpoint(desktop) {
    @include font($heading-medium);
  }

  &--overlay {
    padding-right: 0;

    @include breakpoint(tablet) {
      padding-right: $spacer-xs;
    }

    &-scrolling {
      @include font($heading-small);
    }
  }
}

.product-header__price {
  @include font($heading-medium);

  padding-left: 10px;
  position: relative;
  white-space: nowrap;

  @include breakpoint(tablet) {
    @include font($heading-medium);
  }

  &--cwyp-original {
    color: $grey-400;
    padding-right: $spacer-xxs;
    text-decoration: line-through;
  }

  &--cwyp-discount {
    color: $black;
  }

  &--overlay {
    margin-left: 0;
    padding-left: 0;
  }

  &--scrolling {
    @include font($body-medium);
  }
}

.product-header__pdp-price-badge {
  @include font($heading-xsmall);

  padding: 2px $spacer-xs;
  position: absolute;
  right: $spacer-s;

  @include breakpoint(tablet) {
    right: $product-header-spacer;
  }

  @include breakpoint(desktop) {
    right: 0;
    top: 58px;
  }

  &--bundle {
    background: $green-100;
    color: $green-500;
    white-space: nowrap;

    &-overlay {
      top: 40px;
    }
  }

  &--final-sale {
    background: $red-100;
    color: $red-600;
  }

  &--promo-code {
    background: $red-100;
    color: $red-500;
    font-size: 12px;
    letter-spacing: 0.24px;
    margin-top: $spacer-xxs;
    text-transform: none;
  }

  &--overlay {
    bottom: $spacer-s;

    @include breakpoint(tablet) {
      bottom: unset;
      display: unset;
      margin-top: $spacer-xs;
      right: 0;
      top: unset;
    }
  }
}

.product-header__cwyp-price {
  display: flex;
}

.product-header__stars {
  align-items: baseline;
  display: inline-flex;
  line-height: 0;
  padding: $spacer-xxs 0 $spacer-xs;
  width: 100%;
}

.product-header__review-label {
  @include font($body-small);

  color: $grey-400;
  line-height: 1rem;
  padding-left: $spacer-xxs;
  position: relative;
}

.product-header__review-summary-container {
  margin-top: -$spacer-xxs;
  min-height: 22px;
}

.product-header__traditional-retail-price {
  @include font($body-small);

  button {
    color: $grey-400;
    padding: 0;
  }
}

.product-header__duty-message {
  @include font($body-small);

  color: $grey-400;
  min-height: 1.1rem;
}

.product-header__afterpay-section {
  @include font($body-small);

  align-items: center;
  color: $grey-400;
  display: flex;
  justify-content: center;
  margin: 0 0 $spacer-sm;
  text-align: center;
  transition: opacity 250ms ease-in;
  width: 100%;

  @include breakpoint(tablet) {
    margin-left: 40px;
  }

  @include breakpoint(desktop) {
    margin-left: 0;
  }

  img {
    height: 13px;
    margin-top: 2px;
    width: 62px;
  }
}

.product-header__afterpay-section--hidden {
  display: none;
}

.product-header__klarna-osm {
  height: $spacer-m;

  &--show-combined {
    margin-bottom: $spacer-s;
  }
}

.product-header__related-product {
  @include font($body-small);

  color: $grey-400;
  margin: $spacer-xs 0;

  a {
    color: $grey-400;
  }
}

.product-heading {
  &__price--overlay {
    padding-top: $spacer-xxs;
    position: relative;

    @include breakpoint(desktop) {
      padding-top: 0;
    }
  }
}

#klarna-osm::part(osm-container) {
  @include font($body-medium);

  background-color: #fff;
  border-color: #eae7e4;
  border-radius: 5px;
  border-style: solid;
  border-width: 0;
  color: $grey-400;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

#klarna-osm::part(osm-message) {
  display: block;
  margin-inline: 0 0;
}
